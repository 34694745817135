<template>
  <div class="row justify-content-center" v-if="currentNode.webform">
    <div class="col-12 col-xl-10 col-xxl-8 offset-0 offset-xl-1 offset-xl-2">
      <div class="webform-contact">
        <square-grid v-if="isLoadingProgress"
                     :background="'#ddd'"></square-grid>
        <div class="card" v-bind:class="{ 'loading-progress': isLoadingProgress }">
          <div class="card-body pl-2">
            <h3>{{$t('webform.label')}}</h3>
            <form @submit.prevent="validateBeforeSubmit" @submit="onSubmit" class="row g-3 needs-validation" ref="mainWebform">
              <div class="control col-md-6">
                <div class="control-item">
                  <input name="name" id="name" class="form-control" type="text" :placeholder="$t('webform.name')" required
                         v-model="name"
                         v-validate="'required|alpha_num'"
                         :class="{'input': true, 'is-danger': errors.has('name') }">
                  <div class="control-item__field-warning" v-show="errors.has('name')">
                    <i class="fa fa-warning"></i>
                    <span class="help is-danger">{{ errors.first('name') }}</span>
                  </div>
                </div>
              </div>
              <div class="control col-md-6">
                <div class="control-item">
                  <input name="email" id="email" class="form-control" type="email" :placeholder="$t('webform.email')" required
                         v-model="email"
                         v-validate="'required|email'"
                         :class="{'input': true, 'is-danger': errors.has('email') }">
                  <div class="control-item__field-warning" v-show="errors.has('email')">
                    <i class="fa fa-warning"></i>
                    <span class="help is-danger">{{ errors.first('email') }}</span>
                  </div>
                </div>
              </div>
              <div class="control col-md-12">
                <div class="control-item">
                  <input name="university" id="university" class="form-control" type="text" :placeholder="$t('webform.university')"
                         v-model="university"
                         :class="{'input': true, 'is-danger': errors.has('university') }">
                  <div class="control-item__field-warning" v-show="errors.has('university')">
                    <i class="fa fa-warning"></i>
                    <span class="help is-danger">{{ errors.first('university') }}</span>
                  </div>
                </div>
              </div>
              <div class="control col-md-12">
                <div class="control-item">
                  <textarea name="message" id="message" class="form-control textarea" :placeholder="$t('webform.message')"
                            v-model="message"
                            rows="4"
                            max-rows="6"
                            :class="{'input': true, 'is-danger': errors.has('message') }"/>
                  <div class="control-item__field-warning" v-show="errors.has('message')">
                    <i class="fa fa-warning"></i>
                    <span class="help is-danger">{{ errors.first('message') }}</span>
                  </div>
                </div>
              </div>
              <div class="control col-md-12">
                <div class="control-item">
                  <label class="form-label">{{$t('webform.attachment')}}
                    <input class="form-control" data-vv-as="file" name="file" type="file" required
                           v-validate="'required|ext:zip,rar'"
                           @change="handleFileUpload( $event )"/>
                  </label>
                  <div class="control-item__field-warning" v-show="errors.has('file')">
                    <i class="fa fa-warning"></i>
                    <span class="help is-danger">{{ errors.first('file') }}</span>
                  </div>
                </div>
              </div>
              <div class="ccontrol col-md-12">
                <div class="control-item">
                  <div class="form-check">
                    <input name="terms" class="form-check-input" type="checkbox" value="" id="invalidCheck" required
                           v-model="terms"
                           :class="{'input': true, 'is-danger': errors.has('terms') }"/>
                    <label class="form-check-label" for="invalidCheck">
                      {{$t('webform.terms')}}
                    </label>
                    <div class="control-item__field-warning" v-show="errors.has('terms')">
                      <i class="fa fa-warning"></i>
                      <span class="help is-danger">{{ errors.first('terms') }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="control col-md-12">
                <button class="btn btn-secondary" type="submit">{{$t('webform.send')}}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import { mapActions, mapState } from "vuex";
    import {SquareGrid} from 'vue-loading-spinner'
    export default {
        name: "Webform",
        components: {
            SquareGrid
        },
        data: () => ({
            isLoadingProgress: false,
            /**
             * FORM
             */
            name: '',
            email: '',
            university: '',
            message: '',
            terms: '',
            file: ''
        }),
        methods: {
            ...mapActions({
                submitForm: 'submitForm',
                submitAttachments: 'submitAttachments',
            }),
            elementFadeOut (fade = 550) {
                this.isLoadingProgress = true;
                return new Promise((resolve) => {
                    setTimeout(() => {
                        resolve()
                    }, fade)
                })
            },
            onSubmit() {
                const ext = this.file.name.split('.').pop();
                if (['zip','rar'].includes(ext)) {
                    const reader = new FileReader();
                    reader.readAsDataURL(this.file);
                    reader.onload = (event) => {
                        this.elementFadeOut().then( () => {
                                this.submitAttachments({
                                    token: this.sessionToken,
                                    fileName: this.file.name,
                                    data: event.target.result
                                }).then( response => {
                                    this.submitWebform(response);
                                });
                            }
                        );
                    };
                }
            },
            submitWebform(fileHandler) {
                const formData = {
                    webform_id: "contact",
                    name: this.name,
                    email: this.email,
                    university: this.university,
                    message: this.message,
                    terms: this.terms,
                    attachment: fileHandler.fid[0].value,
                };
                this.submitForm( formData ).then( () => {
                    this.name = '';
                    this.email = '';
                    this.university = '';
                    this.message = '';
                    this.terms = false;
                    this.file = null;
                    this.$refs.mainWebform.reset();
                    this.isLoadingProgress = false;
                });
            },
            handleFileUpload( event ){
                this.file = event.target.files[0];
            },
            validateBeforeSubmit() {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        console.log('Form Submitted!');
                        return;
                    }
                    console.log('Correct them errors!');
                });
            }
        },
        computed: mapState({
            currentNode: state => state.currentNode,
            sessionToken: state => state.sessionToken,
        }),
        created() {
            if ( typeof this.$route.params !== "undefined") {
                this.slug = this.$route.params.slug;
            }
        }
    }
</script>

<style scoped lang="scss">
  @import '../../styles/bootstrap';
  .webform-contact {
    margin-top: 3rem;
    margin-bottom: 3rem;
    position: relative;
    .spinner {
      transform: translate(-20px,-20px);
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 10;
    }
  }
  .card {
    @include transition(all 0.4s ease 0s);
  }
  h3 {
    margin: 0 0 2rem;
    font-weight: 700;
  }
  .card {
    border: none;
  }
  .control {
    padding-top: 0;
    &-item {
      padding-bottom: 1rem;
      position: relative;
      &__field-warning {
        position: absolute;
        width: 100%;
        bottom: -0.25rem;
        line-height: 110%;
        font-size: 80%;
      }
    }
  }

  .form-control {
    @include border-radius(0);
    &.input[type=text],
    &.input[type=email],
    &.input.textarea {
      padding: 0.75rem;
    }
  }
  .is-danger {
    color: #c88383;
  }
  .loading-progress {
    opacity: 0.25;
    filter: blur(0.5rem);
  }
</style>
